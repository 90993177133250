import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { getButtonClassName } from '@presentation/Button';
import isTrustedHost from '@utils/isTrustedHost';
import IconExternalLink from '@icons/IconExternalLink';
const scrollToAnchor = (activeAnchor) => {
    setTimeout(() => {
        if (!(activeAnchor || activeAnchor === '#'))
            return;
        const elem = document.querySelector(activeAnchor);
        if (!elem)
            return;
        elem.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
        });
    }, 100);
};
const Link = ({ children, circle = false, className = null, eventName, isExternal, small = false, text, to, variant = null, ...otherProps }) => {
    if (!to) {
        return (<span className={className || getButtonClassName(variant, small, circle)} data-tracking-code={eventName}>
        {text?.length ? text : children}
      </span>);
    }
    if (isExternal &&
        (to.startsWith('http') ||
            to.startsWith('#') ||
            (to.startsWith('/') && to.includes('#')))) {
        const rel = isTrustedHost(to) ? undefined : 'noopener noreferrer';
        let target = '_blank';
        if (to.startsWith('/') && to.includes('#')) {
            target = '_self';
        }
        return (<a className={className || getButtonClassName(variant, small, circle)} href={to} rel={rel} onClick={event => {
            if (to.startsWith('#')) {
                event.preventDefault();
                scrollToAnchor(to);
            }
        }} 
        // eslint-disable-next-line react/jsx-no-target-blank
        target={target} data-tracking-code={eventName} {...otherProps}>
        {text?.length ? text : children}
        <IconExternalLink />
      </a>);
    }
    return (<GatsbyLink className={className || getButtonClassName(variant, small, circle)} to={to} data-tracking-code={eventName} {...otherProps}>
      {text?.length ? text : children}
    </GatsbyLink>);
};
export default Link;
