/**
 * Check if the url includes a trusted host.
 * @param url
 */
export default function isTrustedHost(url) {
    if (url.match(/kontent\.ai/)) {
        return true;
    }
    if (url.match(/xperience\.io/)) {
        return true;
    }
    if (url.match(/jobs\.kentico\.com/)) {
        return true;
    }
    return false;
}
