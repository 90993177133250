import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import Header from '@connected/Header';
import Footer from '@connected/Footer';
import '@sass/shared/index.scss';
import { LocationProvider } from './LocationProvider';
const Layout = ({ children, location, path }) => {
    return (<LocationProvider location={location}>
      <div className="viewport-wide">
        <Header />
        <AnimatePresence initial={false} exitBeforeEnter>
          <motion.main key={path} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            {children}
          </motion.main>
        </AnimatePresence>
        <Footer />
      </div>
    </LocationProvider>);
};
export default Layout;
