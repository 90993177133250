import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import FooterLayout from '@presentation/Footer';
import getCtaModel from '@utils/getCtaModel';
const Footer = () => {
    const { kontentItemMaster: masterData } = useFooterData();
    const menuData = masterData.elements?.links?.linked_items?.[0];
    const menuItems = menuData?.elements?.items?.linked_items;
    const menu = menuItems && menuItems.map(item => createMenuItemModel(item, 1));
    const legalItems = masterData?.elements?.legal_links?.linked_items;
    const legalLinks = legalItems && legalItems.map(item => createMenuItemModel(item, 1));
    const social = (masterData.elements?.social?.linked_items ||
        []);
    const socialLinks = social && social.map(createFooterSocialLinks);
    return (<FooterLayout menu={menu} legalLinks={legalLinks} socialLinks={socialLinks}/>);
};
function createFooterSocialLinks(data) {
    return {
        id: data.id,
        eventName: data.elements?.event_name?.value,
        icon: data.elements?.icon?.value,
        link: data.elements?.link?.value,
        name: data.elements?.name?.value,
    };
}
/**
 * Create a navigation item model from the specified menu item data.
 * @param item
 */
function createMenuItemModel(item, level) {
    const children = item.elements?.children?.linked_items;
    const childItems = children || [];
    const [title, to, isExternal] = getCtaModel(item.elements?.title?.value, item.elements?.link__external_?.value, item.elements?.link__internal_?.linked_items?.[0]);
    return {
        children: childItems.map(child => createMenuItemModel(child, level + 1)),
        eventName: item.elements?.event_name?.value,
        id: item.system?.id || '',
        isExternal,
        level,
        title,
        to,
    };
}
export default Footer;
export const KontentItemFooterMenuItemFragment = graphql `
  fragment KontentItemFooterMenuItemFragment on KontentItemMenuItem {
    elements {
      description {
        value
      }
      event_name {
        value
      }
      link__external_ {
        value
      }
      link__internal_ {
        linked_items {
          ... on NodeWithUrl {
            url
          }
        }
      }
      title {
        value
      }
      type {
        value {
          codename
        }
      }
    }
    system {
      id
    }
  }
`;
function useFooterData() {
    const data = useStaticQuery(graphql `
    query MasterFooterQuery {
      kontentItemMaster(system: { codename: { eq: "master" } }) {
        elements {
          links {
            linked_items {
              ... on KontentItemMenu {
                id
                elements {
                  items {
                    linked_items {
                      ... on KontentItemMenuItem {
                        ...KontentItemFooterMenuItemFragment
                        elements {
                          children {
                            linked_items {
                              ... on KontentItemMenuItem {
                                ...KontentItemFooterMenuItemFragment
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          social {
            linked_items {
              ... on KontentItemSocialLink {
                id
                elements {
                  event_name {
                    value
                  }
                  icon {
                    value
                  }
                  link {
                    value
                  }
                  name {
                    value
                  }
                }
              }
            }
          }
          legal_links {
            linked_items {
              ... on KontentItemMenuItem {
                id
                ...KontentItemFooterMenuItemFragment
              }
            }
          }
        }
      }
    }
  `);
    return data;
}
