/* eslint-disable @typescript-eslint/explicit-function-return-type */
require('intersection-observer');

function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

function getParameterByName(name, url) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return '';
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

exports.onRouteUpdate = ({ location }) => {
  const keys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_type',
    'utm_audience',
    'gclid',
  ];

  for (let i = 0; i < keys.length; i++) {
    const param = getParameterByName(keys[i], location.href);
    if (param) {
      setCookie(keys[i], param, 365);
    }
  }
};

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
  } else {
    const [x, y] = getSavedScrollPosition(location);

    window.scrollTo({
      behavior: 'smooth',
      left: x,
      top: y,
    });
  }

  return false;
};
