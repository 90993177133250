import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import MainNavigation from '@connected/MainNavigation';
import Logo from '@icons/Logo';
import { ButtonVariant } from '@presentation/Button/Button.types';
import Link from '@presentation/Link';
const Header = ({ ctaEventName, ctaIsExternal, ctaText, ctaTo, ctaHide, }) => (<header className="header js-header">
    <div className="site-wide">
      <div className="header__main">
        <GatsbyLink className="header__logo" to="/">
          <Logo />
        </GatsbyLink>

        <MainNavigation ctaEventName={ctaEventName} ctaIsExternal={ctaIsExternal} ctaText={ctaText} ctaTo={ctaTo} ctaHide={ctaHide}/>

        {ctaText && ctaTo && (<Link eventName={ctaEventName} isExternal={ctaIsExternal} text={ctaText} to={ctaTo} variant={ButtonVariant.OrangeSolid}/>)}
      </div>
    </div>
  </header>);
export default Header;
