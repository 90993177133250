import React from 'react';
import { ButtonVariant } from './Button.types';
const Button = ({ circle = false, small = false, text, variant = ButtonVariant.OrangeSolid, ...otherProps }) => (<button className={getButtonClassName(variant, small, circle)} {...otherProps}>
    {text}
  </button>);
export default Button;
/**
 * Get the CSS classes for the specified button variant.
 * @param variant The button variant
 */
export function getButtonClassName(variant, small, circle) {
    let className = '';
    switch (variant) {
        case ButtonVariant.BlackBorder:
            className = 'btn btn--black-border';
            break;
        case ButtonVariant.BlueSolid:
            className = 'btn btn--blue';
            break;
        case ButtonVariant.OrangeBorder:
            className = 'btn btn--orange-border';
            break;
        case ButtonVariant.OrangeSolid:
            className = 'btn btn--orange-solid';
            break;
        case ButtonVariant.WhiteBorder:
            className = 'btn btn--white-border';
            break;
        case ButtonVariant.WhiteSolid:
            className = 'btn btn--white-solid';
            break;
        case ButtonVariant.LinkUnderlineArrowOrange:
            className = 'cta-link';
            break;
        case ButtonVariant.LinkUnderlineArrowWhite:
            className = 'cta-link cta-link--white';
            break;
    }
    if (small) {
        className = `${className} btn--small`;
    }
    if (circle) {
        className = `${className} btn--circle`;
    }
    return className.trim();
}
