import { useStaticQuery, graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import HeaderLayout from '@presentation/Header';
import getCtaModel from '@utils/getCtaModel';
const Header = () => {
    const { kontentItemMaster: masterData } = useHeaderData();
    const [ctaText, ctaTo, ctaIsExternal] = getCtaModel(masterData.elements?.header_cta_text?.value, masterData.elements?.header_cta_link__external_?.value, masterData.elements?.header_cta_link__internal_?.linked_items?.[0]);
    const [region, setRegion] = useState(null);
    useEffect(() => {
        getData();
        async function getData() {
            let res;
            let resData;
            try {
                res = await fetch(`https://xperience.io/api/lookup/country-code`);
                resData = await res.json();
            }
            catch (err) {
                resData = 'CZ';
            }
            setRegion(resData);
        }
    });
    return (<HeaderLayout ctaEventName={masterData.elements?.header_cta_event_name?.value} ctaIsExternal={ctaIsExternal} ctaText={ctaText} ctaTo={ctaTo} ctaHide={region}/>);
};
export default Header;
function useHeaderData() {
    const data = useStaticQuery(graphql `
    query MasterQuery {
      kontentItemMaster(system: { codename: { eq: "master" } }) {
        elements {
          header_cta_event_name {
            value
          }
          header_cta_text {
            value
          }
          header_cta_link__internal_ {
            linked_items {
              ... on NodeWithUrl {
                url
              }
            }
          }
          header_cta_link__external_ {
            value
          }
        }
      }
    }
  `);
    return data;
}
