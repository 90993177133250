export var ButtonVariant;
(function (ButtonVariant) {
    ButtonVariant["BlackBorder"] = "BlackBorder";
    ButtonVariant["BlueSolid"] = "BlueSolid";
    ButtonVariant["OrangeSolid"] = "OrangeSolid";
    ButtonVariant["OrangeBorder"] = "OrangeBorder";
    ButtonVariant["WhiteSolid"] = "WhiteSolid";
    ButtonVariant["WhiteBorder"] = "WhiteBorder";
    ButtonVariant["LinkUnderlineArrowWhite"] = "LinkUnderlineArrowWhite";
    ButtonVariant["LinkUnderlineArrowOrange"] = "LinkUnderlineArrowOrange";
})(ButtonVariant || (ButtonVariant = {}));
