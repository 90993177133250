/**
 *
 * @param text
 * @param external
 * @param internal
 */
export default function getCtaModel(text, external, internal) {
    let to = null;
    let isExternal = false;
    if (external) {
        isExternal = true;
        to = external;
    }
    const nodeWithUrl = internal;
    if (nodeWithUrl) {
        to = nodeWithUrl.url || '';
    }
    return [text || '', to, isExternal];
}
