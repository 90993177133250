import classNames from 'classnames';
import Image from 'gatsby-image';
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState, } from 'react';
import IconChevronDown from '@icons/IconChevronDown';
import { ButtonVariant } from '@presentation/Button/Button.types';
import Link from '@presentation/Link';
import { NavigationItemType, } from './Navigation.types';
import { useLocation } from '@presentation/Layout/LocationProvider';
import IconExternalLink from '@icons/IconExternalLink';
const Navigation = ({ ctaEventName, ctaIsExternal, ctaText, ctaTo, items, }) => {
    const navRef = useRef(null);
    // Create an array of refs the same length as the top-level items.
    const dropdownRef = useRef({});
    const [hoverId, setHoverId] = useState(null);
    const [clickId, setClickId] = useState(null);
    const [backgroundStyle, setBackgroundStyle] = useState({});
    const [isNavActive, setIsNavActive] = useState(false);
    const [isNavReady, setIsNavReady] = useState(false);
    const location = useLocation();
    // If the user navigates then clear the navigation state - it shouldn't ever persist!
    useEffect(() => {
        setClickId(null);
        setHoverId(null);
        setIsNavActive(false);
    }, [location?.key]);
    // Handle resizing/repositioning the background element.
    useLayoutEffect(() => {
        if (!navRef.current || !dropdownRef.current) {
            setBackgroundStyle({});
            return;
        }
        if (!hoverId) {
            setBackgroundStyle({});
            return;
        }
        // Get active dropdown element.
        const dropdownElement = dropdownRef.current[hoverId];
        if (!dropdownElement) {
            setBackgroundStyle({});
            return;
        }
        const dropdownRect = dropdownElement.getBoundingClientRect();
        const navRect = navRef.current.getBoundingClientRect();
        // Calculate new background position and size.
        const bgRect = {
            height: dropdownRect.height,
            width: dropdownRect.width,
            top: dropdownRect.top - navRect.top,
            left: dropdownRect.left - navRect.left,
        };
        // @todo: Maybe change this to animate directly instead of using CSS Transitions otherwise
        //        there is an awkward animation when the dropdown is first displayed.
        setBackgroundStyle({
            width: `${bgRect.width}px`,
            height: `${bgRect.height}px`,
            transform: `translate(${bgRect.left}px, ${bgRect.top}px)`,
            top: '0px',
        });
    }, [hoverId]);
    const navClassName = classNames({
        nav: true,
        'is-active': isNavActive,
    });
    const burgerClassName = classNames({
        burger: true,
        'is-active': isNavActive,
        'is-ready': isNavReady,
    });
    const bgClassName = classNames({
        'nav__dropdown-bg': true,
        'is-open': hoverId !== null,
    });
    function isActiveLink(pathname, item, level) {
        if (!pathname)
            return false;
        if (pathname.endsWith('/')) {
            pathname = pathname.slice(0, -1);
        }
        if (level === 1) {
            return item.children.some(child => child.to === pathname);
        }
        else {
            return item.to === pathname;
        }
    }
    return (<Fragment>
      <nav ref={navRef} className={navClassName}>
        <div className={bgClassName} style={backgroundStyle}></div>

        <ul className="nav__inner">
          {items.map(item => {
        const hasDropdown = !!(item.children && item.children.length);
        const itemClassName = classNames({
            [`nav-level-${item.level}`]: true,
            'has-dropdown': hasDropdown,
            'trigger-enter': hoverId === item.id,
        });
        const linkClassName = classNames({
            'nav-link-primary': true,
            'is-open': clickId === item.id,
            'is-active-link': isActiveLink(location?.pathname, item, 1) ||
                isActiveLink(location?.pathname, item, 0),
        });
        return (<li key={item.id} className={itemClassName} onMouseEnter={() => {
            setHoverId(item.id);
        }} onMouseLeave={() => {
            setHoverId(null);
        }}>
                {!item.to && (<div className={linkClassName} onClick={event => {
            event.preventDefault();
            if (clickId === item.id) {
                setClickId(null);
            }
            else {
                setClickId(item.id);
            }
        }}>
                    {item.title}
                    {hasDropdown && <IconChevronDown />}
                  </div>)}

                {item.to && (<Link className={linkClassName} eventName={item.eventName} isExternal={item.isExternal} text={item.title} to={item.to}/>)}

                {item.to && item.isExternal && <IconExternalLink />}

                {hasDropdown && (<div className="nav__safe-zone">
                    <ul ref={ref => {
            dropdownRef.current[item.id] = ref;
        }} className="nav__dropdown">
                      {item.children.map(child => {
            const linkSecondaryClassName = classNames({
                'nav-link-secondary': true,
                'is-active-link': isActiveLink(location?.pathname, child, 2),
            });
            switch (child.type) {
                case NavigationItemType.Highlighted:
                    return (<li key={child.id} className="nav-content nav-content--highlighted" onClick={() => setIsNavActive(false)}>
                                <Link className={linkSecondaryClassName} eventName={child.eventName} isExternal={child.isExternal} text={child.title} to={child.to}/>
                              </li>);
                case NavigationItemType.Rich:
                    return (<li key={child.id} className="nav-content nav-content--rich-content" onClick={() => setIsNavActive(false)}>
                                <Link eventName={child.eventName} isExternal={child.isExternal} to={child.to}>
                                  {child.image && (<Image className="nav-content__image" alt={child.title} fixed={child.image}/>)}
                                  {child.content && (<p className="nav-content__description">
                                      {child.content}
                                    </p>)}
                                  <span className="nav-content__cta">
                                    Learn more <IconChevronDown />
                                  </span>
                                </Link>
                              </li>);
                case NavigationItemType.Simple:
                    return (<li key={child.id} className="nav-content " onClick={() => setIsNavActive(false)}>
                                <Link className={linkSecondaryClassName} eventName={child.eventName} isExternal={child.isExternal} text={child.title} to={child.to}/>
                              </li>);
                case NavigationItemType.Summary:
                    return (<li key={child.id} className="nav-content nav-content--summary" onClick={() => setIsNavActive(false)}>
                                <Link className={linkSecondaryClassName} eventName={child.eventName} isExternal={child.isExternal} text={child.title} to={child.to}/>
                              </li>);
                default:
                    return null;
            }
        })}
                    </ul>
                  </div>)}
              </li>);
    })}

          {ctaText && ctaTo && (<li className="nav-cta">
              <Link eventName={ctaEventName} isExternal={ctaIsExternal} text={ctaText} to={ctaTo} variant={ButtonVariant.OrangeSolid}/>
            </li>)}
        </ul>
      </nav>
      <div className={burgerClassName} onClick={event => {
        event.preventDefault();
        setIsNavReady(true);
        setIsNavActive(!isNavActive);
        setClickId(null);
    }}>
        <span className="burger__line burger__line--top"></span>
        <span className="burger__line burger__line--mid"></span>
        <span className="burger__line burger__line--bottom"></span>
      </div>
    </Fragment>);
};
export default Navigation;
