import React, { Fragment } from 'react';
import IconGeneric from '@icons/IconGeneric';
import Link from '@presentation/Link';
import Logo from '@icons/Logo';
const FooterNavigation = ({ items }) => {
    return (<Fragment>
      {items.map(item => {
        return (<div className="footer__column" key={item.id}>
            <nav className="footer__nav">
              <ul>
                <li>
                  <Link eventName={item.eventName} isExternal={item.isExternal} text={item.title} to={item.to}/>
                </li>
                {item.children.map(child => {
            return (<li key={child.id}>
                      <Link eventName={child.eventName} isExternal={child.isExternal} text={child.title} to={child.to}/>
                    </li>);
        })}
              </ul>
            </nav>
          </div>);
    })}
    </Fragment>);
};
const FooterSocial = ({ eventName, icon, link, }) => {
    return (<div className="footer__social-link">
      {icon && link && (<Link eventName={eventName} isExternal to={link}>
          <IconGeneric iconName={icon}/>
        </Link>)}
    </div>);
};
const FooterLegal = ({ items }) => {
    return (<Fragment>
      {items.map(item => {
        return (<li key={item.id}>
            <Link eventName={item.eventName} isExternal={item.isExternal} text={item.title} to={item.to}/>
          </li>);
    })}
    </Fragment>);
};
const Footer = ({ socialLinks, menu, legalLinks }) => {
    return (<footer className="footer">
      <section className="footer__container">
        <div className="footer__middle">
          {menu && <FooterNavigation items={menu}/>}

          {socialLinks && (<div className="footer__column footer__column--social">
              <Logo />
              <div className="footer__social">
                {socialLinks.map(social => {
        return (<FooterSocial key={social.id} eventName={social.eventName} icon={social.icon} link={social.link}/>);
    })}
              </div>
            </div>)}
        </div>

        <div className="footer__bottom">
          <div className="footer__copyright">
            <p>
              Copyright &copy; {new Date().getFullYear()} Kentico Software. All
              rights reserved.
            </p>
          </div>
          {legalLinks && (<nav className="footer__nav footer__nav--aux">
              <ul>
                <FooterLegal items={legalLinks}/>
              </ul>
            </nav>)}
        </div>
      </section>
    </footer>);
};
export default Footer;
